import { css } from 'styled-components'

import type {
  Devices,
  Colors,
  Fonts,
  Borders,
  Layers,
  Variables,
} from './theme'

export const theme =
  (type: string, prop: string) =>
  (props: Record<string, any>): string =>
    props.theme[type][prop]
export const color = (color: Colors) => theme('color', color)
export const device = (device: Devices) => theme('device', device)
export const font = (font: Fonts) => theme('font', font)
export const border = (border: Borders): (() => string) =>
  // @ts-expect-error TS2322
  theme('border', border)
// @ts-expect-error TS2322
export const layer = (layer: Layers): (() => string) => theme('layer', layer)
export const variables = (variable: Variables) => theme('variables', variable)

export const circleIcon = ({
  size,
  borderWidth,
  givenColor,
  bgColor,
  borderColor,
}: {
  size?: number
  borderWidth?: number | string
  givenColor?: Colors
  bgColor?: Colors
  borderColor?: Colors
}) => css`
  color: ${givenColor || color('brightBlue')};
  width: ${size || 32}px;
  height: ${size || 32}px;
  background-color: ${(bgColor && color(bgColor)) || color('white')};
  border-radius: 50%;
  border: ${borderWidth || 1}px solid ${borderColor || color('coolGrey')};
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  &::before {
    font-size: ${(size || 32) / 2}px;
  }
`
